<template>
    <v-dialog 
        :value="value"
        @change="handleDialogChange"
        max-width="800px"
    >
        <v-card>
            <ValidationObserver ref="move_form">
                <v-card-title>
                    {{ __('Mover os anexos da categoria', 'settings') }} {{ local_category.description }}?
                    <v-spacer />
                    <v-btn icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-subtitle class="pt-2 pb-0">
                    <p>
                        {{ local_category.attachments_count }} {{ __('Anexo(s) estão associados a esta categoria atualmente', 'settings') }}
                    </p>
                </v-card-subtitle>
                <v-card-text class="pt-0 pb-0">
                    <v-row dense>
                        <v-col>
                            <v-text-field
                                :label="__('Mover de', 'settings')"
                                :value="local_category.description"
                                disabled
                            />
                        </v-col>
                        <v-col cols="1" class="d-flex align-center justify-center">
                            <v-icon>mdi-arrow-right</v-icon>
                        </v-col>
                        <v-col>
                            <ValidationProvider
                                name="config_attachment_category.new_category_id"
                                rules="required" 
                                v-slot="{ errors }"
                            >
                                <v-autocomplete
                                    :label="__('Para', 'settings')"
                                    v-model="form.new_category_id"
                                    :items="movable_categories"
                                    item-text="description"
                                    item-value="id"
                                    :error-messages="errors"
                                    :return-object="false"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pt-1">
                    <v-spacer />
                    <v-btn color="primary" :loading="submitting" @click="submit">
                        {{ __('Mover', 'settings') }}
                    </v-btn>
                </v-card-actions>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
import { moveManagementCategory } from '@/services/config/attachment_categories';
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
        category: {
            type: Object
        },
        categories: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            form: {},

            submitting: false
        }
    },
    computed: {
        movable_categories() {
            return this.categories.filter(filter_category => {
                return filter_category.id !== this.local_category.id;
            })
        },
        local_category() {
            return this.category || {
                default_for: [],
            };
        },
    },
    methods: {
        handleDialogChange($event) {
            this.$emit("input", $event);
        },
        close() {
            this.handleDialogChange(false);
            this.form = {};
            this.$refs.move_form.reset();
        },

        async submit() {
            this.submitting = true;
            try {
                const validation = await this.$refs.move_form.validate();
                if (!validation) return;

                await moveManagementCategory(this.local_category.id, {
                    config_attachment_category: this.form
                });
                this.$listeners.submit(this.form);
                this.close();
            } catch (error) {
                if (error.response.status == 422) {
                    this.$refs.move_form.setErrors(error.response?.data?.errors || [])
                }
            } finally {
                this.submitting = false;
            }
        },
    }
}
</script>

<style>

</style>