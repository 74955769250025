<template>
    <v-dialog 
        :value="value"
        @change="handleDialogChange"
        max-width="500px"
        eager
    >
        <v-card>
            <v-card-title class="pl-4">
                {{ __('Deletar', 'settings') }} {{ local_category.description }}?
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text v-show="just_delete">
                {{ __("Esta categoria não possui nenhum anexo associado portanto pode ser excluída sem nenhuma ação. Deseja continuar?", 'settings') }}
            </v-card-text>

            <ValidationObserver ref="delete_form" v-show="!just_delete">
                <template v-if="local_category.attachments_count > 0">
                    <v-card-subtitle class="pt-2 pb-0">
                        <p>
                            {{ local_category.attachments_count }} {{ __('Anexo(s) estão associados a esta categoria', 'settings') }}
                            <br />
                            {{ __('Para deletar essa categoria você deve mover os anexos para outra categoria', 'settings') }}
                        </p>
                    </v-card-subtitle>
                    <v-card-text class="pt-0 pb-0">
                        <v-row dense>
                            <v-col>
                                <v-text-field
                                    :label="__('Mover de', 'settings')"
                                    :value="local_category.description"
                                    disabled
                                />
                            </v-col>
                            <v-col cols="1" class="d-flex align-center justify-center">
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-col>
                            <v-col>
                                <ValidationProvider
                                    name="config_attachment_category.move_form.new_category_id"
                                    rules="required" 
                                    v-slot="{ errors }"
                                >
                                    <v-autocomplete
                                        :label="__('Para', 'settings')"
                                        v-model="move_form.new_category_id"
                                        :items="movable_categories"
                                        item-text="description"
                                        item-value="id"
                                        :return-object="false"
                                        :error-messages="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </template>

                <template v-if="local_category.default_for.length">
                    <v-card-subtitle class="pt-2 pb-0">
                        <span class="font-weight-medium">{{ __('Categoria padrão', 'settings') }}</span>
                        <p>
                            {{ __('Para deletar essa categoria você deve definir uma nova categoria para as seguintes configurações de categorias padrões:', 'settings') }}
                        </p>
                    </v-card-subtitle>
                    <v-card-text class="pt-0 pb-0">
                        <v-list class="pt-0">
                            <div v-for="(config, index) in local_category.default_for" :key="config.id">
                                <v-list-item class="px-0">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ config.description }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                          <div class="pb-1" v-for="(description, index) in getDescriptions(config.comment)" :key="config.id + index">
                                              - {{ description }}
                                          </div>
                                        </v-list-item-subtitle>
                                        <v-list-item-title>
                                            <ValidationProvider
                                                :name="`config_attachment_category.reassign_forms.${index}.new_category_id`"
                                                rules="required" 
                                                v-slot="{ errors }"
                                            >
                                                <v-autocomplete
                                                    :label="__('Nova categoria', 'settings')"
                                                    :value="reassined_category(config.id)"
                                                    @input="handleReassinedCategoryChange(config.id, $event)"
                                                    :items="movable_categories"
                                                    item-text="description"
                                                    item-value="id"
                                                    :return-object="false"
                                                    :error-messages="errors"
                                                />
                                            </ValidationProvider>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-list>
                    </v-card-text>
                </template>
            </ValidationObserver>
            <v-card-actions class="pt-1">
                <v-spacer />
                <v-btn color="primary" :loading="submitting" @click="submit">
                    {{ __('Deletar', 'settings') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { deleteManagementCategory } from '@/services/config/attachment_categories';
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
        category: {
            type: Object
        },
        categories: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            move_form: {},
            reassign_forms: [],

            submitting: false
        }
    },
    computed: {
        local_category() {
            return this.category || {
                default_for: [],
            };
        },
        just_delete() {
            return this.local_category.attachments_count == 0 && this.local_category.default_for.length == 0;
        },
        movable_categories() {
            return this.categories.filter(filter_category => {
                return filter_category.id !== this.local_category.id;
            })
        }
    },
    methods: {
        getDescriptions(comment) {
            return comment.split('\n');
        },
        handleDialogChange($event) {
            this.$emit("input", $event);
        },
        close() {
            this.handleDialogChange(false);
            this.move_form = {};
            this.reassign_forms = [];
            this.$refs.delete_form.reset();
        },

        // Configuration
        reassined_category(config_id) {
            return this.reassign_forms.find(form => {
                return form.config_id == config_id;
            })?.new_category_id;
        },
        handleReassinedCategoryChange(config_id, category_id) {
            this.reassign_forms =  this.reassign_forms.map(form => {
                if (form.config_id == config_id) {
                    form.new_category_id = category_id;
                }
                return form;
            });
        },

        async submit() {
            this.submitting = true;
            try {
                const validation = await this.$refs.delete_form.validate();
                if (!validation) return;

                await deleteManagementCategory(this.local_category.id, {
                    config_attachment_category: {
                        move_form: this.move_form, 
                        reassign_forms: this.reassign_forms
                    }
                });
                this.$listeners.submit();
                this.close();
            } catch (error) {
                if (error.response.status == 422) {
                    this.$refs.delete_form.setErrors(error.response?.data?.errors || [])
                }
            } finally {
                this.submitting = false;
            }
        },

        init() {
            this.reassign_forms = this.local_category.default_for.map(config => {
                return {
                    config_id: config.id,
                    new_category_id: null
                }
            });
        },
    },
    watch: {
        value(value) {
            if (value) {
                this.init();
            }
        }
    }
}
</script>

<style>

</style>