import Vue from "vue";

export async function indexManagementCategories() {
    const response = await Vue.prototype.$http.get('/config/attachments/categories');

    return response.data
}

export async function storeManagementCategory(payload) {
    const response = await Vue.prototype.$http.post(
        'config/attachments/categories',
        payload
    );

    return response.data;
}

export async function updateManagementCategory(category_id, payload) {
    const response = await Vue.prototype.$http.put(
        `config/attachments/categories/${category_id}`,
        payload
    );

    return response.data;
}

export async function moveManagementCategory(category_id, payload) {
    const response = await Vue.prototype.$http.put(
        `config/attachments/categories/move/${category_id}`,
        payload
    );

    return response.data;
}

export async function deleteManagementCategory(category_id, payload) {
    const response = await Vue.prototype.$http.post(`config/attachments/categories/${category_id}/delete`, payload);

    return response.data;
}

export async function indexDefaultCategories() {
    const response = await Vue.prototype.$http.get('/config/attachments/categories/default');

    return response.data
}

export async function updateDefaultCategory(payload) {
    const response = await Vue.prototype.$http.put('config/attachments/categories/default', payload);

    return response.data;
}



