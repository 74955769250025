<template>
    <v-dialog 
        :value="value"
        @change="handleDialogChange"
        max-width="500px"
        persistent
    >
        <v-card>
            <v-card-title class="pl-4">
                {{ title }}
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <ValidationObserver ref="form_category">
                <form @submit.prevent="submit">
                    <v-card-text class="pt-0 pb-0">
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="config_attachment_category.description"
                            rules="required"
                        >
                            <v-text-field
                                v-model="form.description"
                                :label="__('Nome', 'settings')"
                                :error-messages="errors"
                            />
                        </ValidationProvider>
                    </v-card-text>
                    <v-card-actions class="pt-1">
                        <v-spacer />
                        <v-btn color="primary" :loading="submitting" type="submit">
                            {{ __('Salvar', 'settings' ) }}
                        </v-btn>
                    </v-card-actions>
                </form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
import { storeManagementCategory, updateManagementCategory } from '@/services/config/attachment_categories';
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
        category: {
            type: Object
        },
    },
    data() {
        return {
            form: {
                description: null
            },

            submitting: false
        }
    },
    computed: {
        editMode() {
            return this.category != null;
        },
        title() {
            if (this.editMode) {
                return this.__('Editar categoria', 'settings')  + this.local_category.description; 
            }

            return this.__('Nova categoria', 'settings');
        },
        local_category() {
            return this.category || {
                default_for: [],
            };
        },
    },
    methods: {
        handleDialogChange($event) {
            this.$emit("input", $event);
        },
        close() {
            this.handleDialogChange(false);
            this.form = {};
            this.$refs.form_category.reset();
        },

        async submit() {
            this.submitting = true;
            try {
                const validation = await this.$refs.form_category.validate();
                if (!validation) return;

                if (this.editMode) {
                    await updateManagementCategory(this.local_category.id, {
                        config_attachment_category: this.form
                    });
                } else {
                    await storeManagementCategory({
                        config_attachment_category: this.form
                    });
                }
                this.$listeners.submit();
                this.close();
            } catch (error) {
                if (error.response.status == 422) {
                    this.$refs.delete_form.setErrors(error.response?.data?.errors || [])
                }
            } finally {
                this.submitting = false;
            }
        },
    },
    watch: {
        value: {
            handler(value) {
                if (value && this.editMode) {
                    this.form.description = this.local_category.description;
                }
            },
            deep: true
        },
    }
}
</script>

<style>

</style>