<template>
    <div>
        <v-row>
            <v-col>
                <v-card flat color="#f1f1f1" style="max-width: 600px">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="title">
                                {{ __('Gestão de categorias', 'settings') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-text class="pt-0">
                        {{ __('As categorias servem para organizarem os anexos de acordo com suas características', 'settings') }}
                    </v-card-text>
                </v-card>

                <v-card flat class="ma-4" style="max-width: 600px">
                    <v-card-title>
                        {{ __('Categorias', 'settings') }}
                        <v-spacer />
                        <v-icon @click="getData" left>mdi-refresh</v-icon>
                        <v-icon color="primary" @click="createAttachment">mdi-plus</v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-alert
                          outlined
                          type="warning"
                          border="left"
                          dense
                          class="mb-0"
                      >
                      {{ __("Atenção ao remover/renomear categorias que possuem anexos já associados Se preciso, crie uma nova categoria e mova os anexos para ela", 'settings') }}
                      </v-alert>
                    </v-card-text>
                    <template v-if="!fetching">
                      <v-list>
                          <div v-for="(category, index) in categories" :key="`category-${index}-${category.id}`">
                              <v-list-item>
                                  <v-list-item-content>
                                      <v-list-item-title>{{ category.description }}</v-list-item-title>
                                      <v-list-item-subtitle>
                                          {{ 
                                              category.attachments_count > 0
                                              ? `${category.attachments_count} anexo(s) - ${category.formatted_total_size}`
                                              : __('Nenhum anexo associado', 'settings')
                                          }}
                                      </v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                      <div>
                                          <v-btn :disabled="category.attachments_count == 0" icon @click="moveAttachments(category)">
                                              <v-icon>mdi-arrow-right</v-icon>
                                          </v-btn>
                                          <v-btn icon @click="editCategory(category)">
                                              <v-icon>mdi-pencil</v-icon>
                                          </v-btn>
                                          <v-btn icon @click="confirmDeleteCategory(category)">
                                              <v-icon>mdi-delete</v-icon>
                                          </v-btn>
                                      </div>
                                  </v-list-item-action>
                              </v-list-item>
                              <v-divider v-if="(index + 1) < categories.length"></v-divider>
                          </div>
                      </v-list>
                    </template>
                    <v-skeleton-loader
                      v-else
                      type="list-item,list-item,list-item,list-item,list-item,list-item"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
        </v-row>

        <CategoryFormDialog
            v-model="form_dialog"
            :category="category_to_edit"
            @submit="getData"
        />

        <DeleteAttachmentCategoryDialog
            v-model="delete_dialog"
            :category="category_to_delete"
            :categories="categories"
            @submit="getData"
        />

        <MoveCategoryAttachmentsDialog
            v-model="move_dialog"
            :category="category_to_move"
            :categories="categories"
            @submit="getData"
        />
    </div>
</template>

<script>
import { indexManagementCategories } from '@/services/config/attachment_categories';

import CategoryFormDialog from '@/components/config/CategoryFormDialog';
import DeleteAttachmentCategoryDialog from '@/components/config/DeleteAttachmentCategoryDialog';
import MoveCategoryAttachmentsDialog from '@/components/config/MoveCategoryAttachmentsDialog';
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    components: {
        CategoryFormDialog,
        DeleteAttachmentCategoryDialog,
        MoveCategoryAttachmentsDialog
    },
    data() {
        return {
            fetching: false,
            categories: [],

            // Deletes
            delete_dialog: false,
            category_to_delete: null,

            // Move
            move_dialog: false,
            category_to_move: null,

            // Edit and Create
            form_dialog: false,
            category_to_edit: null,
        }
    },

    methods: {
        confirmDeleteCategory(category) {
            this.category_to_delete = category;
            this.delete_dialog = true;
        },
        moveAttachments(category) {
            this.category_to_move = category;
            this.move_dialog = true;
        },
        editCategory(category) {
            this.category_to_edit = category;
            this.form_dialog = true;
        },
        createAttachment() {
            this.form_dialog = true;
        },

        async getData() {
            this.fetching = true;
            const response = await indexManagementCategories();
            this.categories = response;
            this.fetching = false;
        }
    },

    created() {
        this.getData();
    }
}
</script>